import Vue, { ComponentPublicInstance } from 'vue'
import { LocaleObject } from '@nuxtjs/i18n'
import VueI18n from 'vue-i18n'
import { isHomeOffer, isMobileOffer } from '@alao-frontend/core'
import {
  SEOBaseModuleMetadata,
  SEOMeta,
  SEOProductModuleMetadata,
  SEOFAQModuleMetadata,
} from '~/composables/seo.composable/types'
import { staticMetaInfo } from '~/composables/seo.composable/constants'
import { namespace as GRWidgetNamespace, GetterType as GRWidgetGetterType } from '~/store/modules/GRWidget'
import { CURRENCY_NAME } from '~/core/config/constants'

export * from './types'

export function useSEOMetaComposer<
  TContext extends Vue | ComponentPublicInstance = Vue,
> (
  ctx: TContext,
  metadata: SEOBaseModuleMetadata & Partial<{
    product: SEOProductModuleMetadata,
    faq?: SEOFAQModuleMetadata,
    customJSONLD?: Record<string, any>[],
  }>,
) {
  const args: Partial<SEOMeta>[] = [
    composeSEOMetaBase(ctx, metadata),
  ]

  if (metadata.customJSONLD && metadata.customJSONLD.length) {
    args.push(composeFromCustomJSONLD(...metadata.customJSONLD))
  }

  if (!metadata.isExcludeOrgRating) {
    args.push(composeSEOMetaRating(ctx))
  }

  if (metadata.product) {
    args.push(composeSEOMetaProduct(ctx, metadata.product))
  }

  if (metadata.faq) {
    args.push(composeSEOMetaFAQ(ctx, metadata.faq))
  }

  return mergeSEOMeta(...args)
}

export function composeFromCustomJSONLD (...markupArgs: Record<string, any>[]) {
  return {
    script: markupArgs.map(arg => ({
      type: 'application/ld+json',
      json: arg,
    })),
  }
}

export function mergeSEOMeta (...objects: Partial<SEOMeta>[]): SEOMeta {
  return objects.reduce<SEOMeta>((acc, curr) => {
    acc.title = curr.title || acc.title
    acc.meta = curr.meta ? [...acc.meta, ...curr.meta] : acc.meta
    acc.script = curr.script ? [...acc.script, ...curr.script] : acc.script
    return acc
  }, {
    title: '',
    meta: [],
    script: [],
  })
}

export function composeSEOMetaBase<
  TContext extends Vue | ComponentPublicInstance = Vue,
  TMetadata extends SEOBaseModuleMetadata = SEOBaseModuleMetadata,
> (
  ctx: TContext,
  metadata: TMetadata,
): SEOMeta {
  const { $route, localePath, $config, $i18n } = ctx

  const baseUrl: string = $config.BASE_URL
  const currentLocale = $i18n.locale as VueI18n.Locale
  const pageURL = baseUrl.concat($route.path)
  const locales = $i18n.locales as LocaleObject[]
  const localeISOCode = locales
    ? locales
      .find(({ code }) => code === currentLocale)?.iso as string
    : currentLocale
  const ogImage = Object.assign({}, {
    ...staticMetaInfo.ogImage,
    url: baseUrl.concat(staticMetaInfo.ogImage.url),
  }, metadata.image)

  // Build map of links to different locales of the current route
  const mapLinks = locales.reduce<Record<VueI18n.Locale, string>>((acc, locale) => {
    acc[locale.code] = baseUrl.concat(localePath(metadata.route, locale.code))
    return acc
  }, {})

  // Build canonical urls
  const canonicalUrls = locales
    .reduce<any[]>((acc, locale) => {
      if (locale.code === $i18n.locale) {
        acc.push({ hid: 'i18n-can-'.concat(locale.iso || locale.code), rel: 'canonical', hreflang: locale.iso, href: mapLinks[locale.code] })
      } else {
        acc.push({ hid: 'i18n-alt-'.concat(locale.iso || locale.code), rel: 'alternate', hreflang: locale.iso, href: mapLinks[locale.code] })
      }

      return acc
    }, [{
      hid: 'i18n-xd', rel: 'alternate', hreflang: 'x-default', href: mapLinks.de,
    }])
    .sort(a => a.rel === 'canonical' ? -1 : 1) // Sort links so that the canonical link is the first one

  // Compose meta tags
  const baseMetaTags = [
    { hid: 'description', name: 'description', content: metadata.description },
    { hid: 'keywords', name: 'keywords', content: metadata.keywords },

    // OG meta tags
    { hid: 'og:locale', property: 'og:locale', content: localeISOCode },
    { hid: 'og:type', property: 'og:type', content: 'object' },
    { hid: 'og:title', property: 'og:title', content: metadata.title },
    { hid: 'og:description', property: 'og:description', content: metadata.description },
    { hid: 'og:url', property: 'og:url', content: pageURL },
    { hid: 'og:site_name', property: 'og:site_name', content: staticMetaInfo.siteName },
    { hid: 'og:image', property: 'og:image', content: ogImage.url },
    { hid: 'og:image:width', property: 'og:image:width', content: ogImage.width },
    { hid: 'og:image:height', property: 'og:image:height', content: ogImage.height },

    // OG Twitter meta tags
    { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    { hid: 'twitter:title', name: 'twitter:title', content: staticMetaInfo.siteName },
    { hid: 'twitter:site', name: 'twitter:site', content: staticMetaInfo.twitter },

    // DC meta tags
    { hid: 'DC.Publisher', name: 'DC.Publisher', content: staticMetaInfo.siteName },
    { hid: 'DC.Publisher.Address', name: 'DC.Publisher.Address', content: staticMetaInfo.email },
    { hid: 'DC.Creator', name: 'DC.Creator', content: staticMetaInfo.siteName },
    { hid: 'DC.Type', name: 'DC.Type', content: 'text/html' },
    { hid: 'DC.Description', name: 'DC.Description', content: metadata.description, lang: $i18n.locale.toUpperCase(), 'xml:lang': $i18n.locale.toUpperCase() },
    { hid: 'DC.Language', name: 'DC.Language', content: $i18n.locale.toUpperCase() },
    { hid: 'DC.Rights', name: 'DC.Rights', content: staticMetaInfo.copyrights },
    { hid: 'DC.title', name: 'DC.title', content: metadata.title },

    // Geo meta tags
    { hid: 'geo.region', name: 'geo.region', content: staticMetaInfo.geo.region },
    { hid: 'geo.placename', name: 'geo.placename', content: staticMetaInfo.geo.placeName },
    { hid: 'geo.position', name: 'geo.position', content: [staticMetaInfo.geo.latitude, staticMetaInfo.geo.longitude].join(';') },
    { hid: 'ICBM', name: 'ICBM', content: [staticMetaInfo.geo.latitude, staticMetaInfo.geo.longitude].join(', ') },
  ]

  const schemaOrg = [
    {
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'Webpage',
        name: metadata.title,
        description: metadata.description,
        url: pageURL,
        image: {
          '@type': 'ImageObject',
          url: ogImage.url,
          width: parseInt(ogImage.width),
          height: parseInt(ogImage.height),
        },
        primaryImageOfPage: {
          '@type': 'ImageObject',
          url: ogImage.url,
        },
      },
    },
  ]

  return {
    title: metadata.title,
    meta: [
      ...baseMetaTags,
      ...canonicalUrls,
      ...(
        metadata.isNoIndex
          ? [{ hid: 'robots', name: 'robots', content: 'noindex' }]
          : []
      ),
    ],
    script: [
      ...schemaOrg,
    ],
  }
}

function composeSEOMetaRating<
  TContext extends Vue | ComponentPublicInstance = Vue,
> (
  ctx: TContext,
): Pick<SEOMeta, 'script'> {
  const rating = ctx.$store.getters[`${GRWidgetNamespace}/${GRWidgetGetterType.RATING}`]
  const reviews = ctx.$store.getters[`${GRWidgetNamespace}/${GRWidgetGetterType.REVIEWS}`]

  if (!rating || !reviews) {
    return {
      script: [],
    }
  }

  return {
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'AggregateRating',
          ratingValue: String(rating),
          reviewCount: String(reviews),
          itemReviewed: {
            '@type': 'Organization',
            name: 'alao',
          },
        },
      },
    ],
  }
}

function composeSEOMetaProduct<
  TContext extends Vue | ComponentPublicInstance = Vue,
  TMetadata extends SEOProductModuleMetadata = SEOProductModuleMetadata,
> (
  ctx: TContext,
  metadata: TMetadata,
): Partial<SEOMeta> {
  const { $config, localePath } = ctx

  const baseUrl: string = $config.BASE_URL

  const offerPath = isMobileOffer(metadata.offer)
    ? localePath({ name: 'compare-mobile-slug', params: { slug: metadata.offer.slug } })
    : isHomeOffer(metadata.offer)
      ? localePath({ name: 'compare-home-slug', params: { slug: metadata.offer.slug } })
      : localePath({ name: 'compare-bundle-slug', params: { slug: metadata.offer.slug } })

  const offerUrl = new URL(offerPath, baseUrl)

  return {
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: metadata.offer.name,
          description: '',
          sku: metadata.offer.id,
          offers: {
            '@type': 'Offer',
            price: metadata.offer.finalPrice,
            priceCurrency: CURRENCY_NAME,
            availability: 'https://schema.org/InStock',
            url: offerUrl.toString(),
          },
          image: baseUrl.concat(staticMetaInfo.ogImage.url),
        },
      },
    ],
  }
}

function composeSEOMetaFAQ<
  TContext extends Vue | ComponentPublicInstance = Vue,
  TMetadata extends SEOFAQModuleMetadata = SEOFAQModuleMetadata,
> (
  _ctx: TContext,
  metadata: TMetadata,
): Partial<SEOMeta> {
  return {
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: metadata.items.map(({ question, answer }) => ({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: answer,
            },
          })),
        },
      },
    ],
  }
}
