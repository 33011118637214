export const staticMetaInfo = {
  siteName: 'alao',
  email: 'support@alao.ch',
  twitter: '@alao_ch',
  copyrights: 'Copyright alao GmbH.  All rights reserved.',
  geo: {
    region: 'CH-ZH',
    placeName: 'Z&uuml;rich',
    latitude: 47.36428,
    longitude: 8.55231,
  },
  ogImage: {
    url: '/og-share.jpg',
    width: '1200',
    height: '630',
  },
}
